import * as React from "react"
import {Link} from "gatsby"
import {StaticImage} from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import ContactUs from "../components/contactus";


const linkStyle = {
    textDecoration: "none",
    color: "grey",
    fontWeight: 800,
}

const hrStyle = {
    margin: "50px auto"
}

const leftTdStyle = {
    padding: "10px",
}

const priceLeftTdStyle = {
    padding: "10px",
}


const IndexPage = () => (
    <Layout>
        <Seo title="SIP Trunk + Gateway"/>

        <div align="center">
            <hr style={hrStyle}/>

            <h1 id="siptrunk_gateway">SIP Trunk + Gateway</h1>

            <div>
                <p>キャリアとエンドユーザーを接続するためのハイパフォーマンスSIPゲートウェイ</p>

                <hr style={hrStyle}/>

                <StaticImage
                    src="../images/siptrunk_gateway/SIPTrunkGateway.png"
                    alt={"CloudPBX"}
                />
            </div>

            <hr style={hrStyle}/>

            <div>
                <h4>システム仕様</h4>
                <table>
                    <colgroup>
                        <col width="30%"/>
                        <col width="70%"/>
                    </colgroup>
                    <tr>
                        <td style={leftTdStyle}>接続方式</td>
                        <td>■ SIP + RTP<br/>
                            IPアドレス認証 or REGISTER認証
                        </td>
                    </tr>
                    <tr>
                        <td style={leftTdStyle}>パフォーマンス</td>
                        <td>■ 20CPS x 360同時通話数 ※サーバスペック等により変化します
                        </td>
                    </tr>
                    <tr>
                        <td style={leftTdStyle}>接続キャリア</td>
                        <td>■ 各種キャリアと接続実績あります。詳細はお問合せください。
                        </td>
                    </tr>
                </table>
            </div>
            <hr style={hrStyle}/>

            <ContactUs />
        </div>
    </Layout>
)

export default IndexPage
